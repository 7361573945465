/* chal-error.css */

.error-message2 {
  background: var(--main-bg);
  padding: 10px 10px 30px;
  text-align: center;
}

.error-message2 .link-icon {
  font-size: 100px;
  display: block;
  height: 110px;
}

.error-message2 .error-number {
  font: 600 100px Leasing, "Segoe UI", "Helvetica Neue", Helvetica, Arial, sans-serif;
  color: var(--primary);
  display: block;
  height: 110px;
}

.error-message2 .error-text {
  font-size: 30px;
  color: var(--primary);
  display: block;
  font-weight: 600;
  line-height: 40px;
}

.error-message2 .error-description {
  font-size: 16px;
  color: var(--black);
  display: block;
  margin: 0 auto;
  font-weight: 400;
  margin-top: 10px;
  margin-bottom: 20px;
  max-width: 400px;
}
